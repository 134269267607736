export const textList = [
    "是但啦",
  "算數啦",
  "唔煩你喇",
  "求其啦",
  "餓死喇",
  "人生苦短",
  "無啖好食",
  "犯法牙",
  "填飽個肚",
  "食花生",
  "廢水",
  "自肥",
  "不想努力了",
  "唔想諗",
  "Whatever la",
  "Si L Dan La",
  "OJ",
  '鐵板燒',
  '燒烤',
  '刺身',
  '串燒',
  '湯品',
  '熟食中心',
  '蠔吧',
  '越南菜',
  '餃子',
  '朱古力',
  '拉麵',
  '印度菜',
  '壽司',
  '糖水',
  '法國菜',
  '韓燒',
  '車仔麵',
  '茶餐廳',
  '西式糕點',
  '甜品',
  '中式糖水',
  '粥品',
  '多國菜',
  '京川滬',
  '海鮮酒家',
  '爐端燒',
  '蛋糕店',
  '漢堡包',
  '意大利菜',
  '港式',
  '扒房',
  '糖水舖',
  '日式西餐',
  '粵菜',
  '泰國菜',
  '雪糕',
  '放題',
  '點心',
  '居酒屋',
  '廚師發辦',
  '韓式炸雞',
  '粉麵',
  '燒臘',
  '台灣菜',
  '烤肉',
  '雲吞',
  '中菜館',
  '海鮮',
  '韓國菜',
  '烏冬',
  '燒烤場',
  '任食',
  '西式',
  '懷石料理',
  '日本菜',
  '大牌檔',
  '三文治',
  '米線',
  '火鍋',
  '冰室',
  '中式包點',
  '蟹店',
  '唐餅',
  '酒店餐廳',
  '乳酪',
  '快餐店',
  '蒸氣火鍋',
  '酒樓',
  '自助餐',
  '糖果店',
  '咖啡店',
  "珍珠is纖維",
  'Food Boy',
  "幫我諗"
]